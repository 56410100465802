import _maxBy from 'lodash/maxBy'
import _cloneDeep from 'lodash/cloneDeep'
import _uniq from 'lodash/uniq'
import _keyBy from 'lodash/keyBy'
import _groupBy from 'lodash/groupBy'
import _minBy from 'lodash/minBy'
import _isEqual from 'lodash/isEqual'
import _uniqBy from 'lodash/uniqBy'
import { getDefaultTemplateForServices, getDefaultCategoriesForService, getCategoryColor } from './default'

class Layout {
  constructor (options) {
    this.horizontalCompact = typeof (options.horizontalCompact) !== 'undefined' ? options.horizontalCompact : true
    this.services = options.services || []
    this.colNum = options.colNum || 12
    this.minWidth = options.minWidth || 3
    this.rowHeight = options.rowHeight || 1
    this.margin = options.margin || [10, 10]
    this.preventCollision = options.preventCollision || false
    this.lite = options.lite || false

    this.updateTiles(options.tiles || [])
  }

  _initLayout (tiles) {
    if (!tiles.length) {
      this.tiles = getDefaultTemplateForServices(this.services)
    } else {
      // Add service if in project.services and not in project.display_settings.home_layout
      let dmInServices, dpeInServices, amInServices, mlmInServices, ccInServices, iamInServices, appserviceInServices
      let dmInTiles, dpeInTiles, amInTiles, mlmInTiles, ccInTiles, iamInTiles, appserviceInTiles, analyticsInTiles, artificialIntelligenceInTiles, adminToolsInTiles, applicationServiceInTiles

      this.services.forEach(s => {
        if (s === 'dm') dmInServices = true
        if (s === 'dpe') dpeInServices = true
        if (s === 'am') amInServices = true
        if (s === 'mlm') mlmInServices = true
        if (s === 'control-center') ccInServices = true
        if (s === 'iam') iamInServices = true
        if (s === 'appservice') appserviceInServices = true
      })

      this.tiles.forEach(t => {
        // services
        if (t.i === 'dm') dmInTiles = true
        if (t.i === 'dpe') dpeInTiles = true
        if (t.i === 'am') amInTiles = true
        if (t.i === 'mlm') mlmInTiles = true
        if (t.i === 'control-center') ccInTiles = true
        if (t.i === 'iam') iamInTiles = true
        if (t.i === 'app' || t.i === 'api') appserviceInTiles = true

        // categories
        if (t.i === 'analytics') analyticsInTiles = true
        if (t.i === 'artificial-intelligence') artificialIntelligenceInTiles = true
        if (t.i === 'admin-tools') adminToolsInTiles = true
        if (t.i === 'application-services') applicationServiceInTiles = true
      })

      const hasDm = dmInServices && !dmInTiles
      const hasDpe = dpeInServices && !dpeInTiles
      const hasAm = amInServices && !amInTiles
      const hasMlm = mlmInServices && !mlmInTiles
      const hasCc = ccInServices && !ccInTiles
      const hasIam = iamInServices && !iamInTiles
      const hasAppService = appserviceInServices && !appserviceInTiles

      if (hasDm || hasDpe || hasAm) {
        if (!analyticsInTiles) this.addCategoryInLayout('analytics')
        if (hasDm) {
          this.addTileInDefaultCategory('data-catalog')
          this.addTileInDefaultCategory('lakehouse')
        }
        if (hasAm) this.addTileInDefaultCategory('am')
        if (hasDpe) this.addTileInDefaultCategory('dpe')
      }
      if (hasMlm) {
        if (!artificialIntelligenceInTiles) this.addCategoryInLayout('artificial-intelligence')
        this.addTileInDefaultCategory('mlm')
      }
      if (hasCc || hasIam) {
        if (!adminToolsInTiles) this.addCategoryInLayout('admin-tools')
        if (hasCc) this.addTileInDefaultCategory('control-center')
        if (hasIam) this.addTileInDefaultCategory('iam')
      }
      if (hasAppService) {
        if (!applicationServiceInTiles) this.addCategoryInLayout('application-services')
        this.addTileInDefaultCategory('api')
        this.addTileInDefaultCategory('app')
      }

      // Remove service if not in project.services and in project.display_settings.home_layout
      const hasNotDm = !dmInServices && dmInTiles
      const hasNotDpe = !dpeInServices && dpeInTiles
      const hasNotAm = !amInServices && amInTiles
      const hasNotMlm = !mlmInServices && mlmInTiles
      const hasNotCc = !ccInServices && ccInTiles
      const hasNotIam = !iamInServices && iamInTiles
      const hasNotAppService = !appserviceInServices && appserviceInTiles

      if (hasNotDm || hasNotDpe || hasNotAm) {
        if (analyticsInTiles && hasNotDm && hasNotDpe && hasNotAm) this.tiles = this.tiles.filter(t => t.i !== 'analytics')
        if (hasNotDm) {
          this.tiles = this.tiles.filter(t => t.i !== 'dm')
          this.tiles = this.tiles.filter(t => t.i !== 'data-catalog')
          this.tiles = this.tiles.filter(t => t.i !== 'lakehouse')
        }
        if (hasNotDpe) this.tiles = this.tiles.filter(t => t.i !== 'dpe')
        if (hasNotAm) this.tiles = this.tiles.filter(t => t.i !== 'am')
      }
      if (hasNotMlm) {
        if (artificialIntelligenceInTiles && hasNotMlm) this.tiles = this.tiles.filter(t => t.i !== 'artificial-intelligence')
        this.tiles = this.tiles.filter(t => t.i !== 'mlm')
      }
      if (hasNotCc || hasNotIam) {
        if (adminToolsInTiles && hasNotCc && hasNotIam) this.tiles = this.tiles.filter(t => t.i !== 'admin-tools')
        if (hasNotCc) this.tiles = this.tiles.filter(t => t.i !== 'control-center')
        if (hasNotIam) this.tiles = this.tiles.filter(t => t.i !== 'iam')
      }
      if (hasNotAppService) {
        if (applicationServiceInTiles) this.tiles = this.tiles.filter(t => t.i !== 'application-services')
        this.tiles = this.tiles.filter(t => t.i !== 'api')
        this.tiles = this.tiles.filter(t => t.i !== 'app')
      }
    }

    // If category has no services -> remove this category
    this._removeEmptyCategories()

    // If tile has no category -> put it in default category
    // TODO

    // remove doubles
    this.tiles = _uniqBy(this.tiles, 'i')
  }

  /**
   * Add category in layout
   */
  addCategoryInLayout (category) {
    // Add at the bottom
    this.tiles.push({
      x: 0,
      y: this.getBottomLeft(),
      width: 1,
      height: 1,
      i: category,
      color: getCategoryColor(category),
      type: 'category'
    })
  }

  _removeEmptyCategories () {
    const categoriesToRemove = []
    this._categories.forEach(category => {
      const tiles = this._tilesByCategories[category]
      if (!tiles || !tiles.length) categoriesToRemove.push(category)
    })
    if (categoriesToRemove.length) {
      this.tiles = this.tiles.filter(t => !categoriesToRemove.includes(t.i))
    }
  }

  /**
   * Add tile in default category
   */
  addTileInDefaultCategory (service) {
    const category = getDefaultCategoriesForService(service) || 'custom'
    if (category === 'custom') this.addCategoryInLayout('custom')

    const categoryTile = this.tiles.find(t => t.i === category)

    // If space in category
    let space = null
    for (let i = categoryTile.x; i < categoryTile.x + categoryTile.width; i++) {
      for (let j = categoryTile.y; j < categoryTile.y + categoryTile.height; j++) {
        if (!this._getFirstCollision(this.tiles, { x: i, y: j, width: 1, height: 1 }, 'service')) {
          space = { x: i, y: j }
          break
        }
        if (space) break
      }
    }

    if (space) {
      this.tiles.push({
        x: space.x,
        y: space.y,
        width: 1,
        height: 1,
        i: service,
        type: 'service',
        category
      })
    } else if (categoryTile.x + categoryTile.width < 4 && !this._getFirstCollision(this.tiles, { x: categoryTile.x + categoryTile.width, y: categoryTile.y, width: 1, height: 1 }, 'all')) {
      // Test if space on right
      this.tiles.push({
        x: categoryTile.x + categoryTile.width,
        y: categoryTile.y,
        width: 1,
        height: 1,
        i: service,
        type: 'service',
        category
      })
    } else {
      // Add bottom category
      this.tiles.push({
        x: categoryTile.x,
        y: categoryTile.y + categoryTile.height,
        width: 1,
        height: 1,
        i: service,
        type: 'service',
        category
      })
    }
  }

  getBottomLeft () {
    const tileBottomLeft = _maxBy(this.tiles.filter(t => t.x === 0), 'y')
    return tileBottomLeft.y + tileBottomLeft.height
  }

  /**
   * Update originalTiles from tiles
   */
  updateTiles (newTiles) {
    this.tiles = newTiles

    this._initLayout(this.tiles)

    this.validateTiles()
    this.updateOriginalTiles()

    this.compact()
  }

  /**
   * Update originalTiles from tiles
   */
  updateOriginalTiles () {
    this._removeEmptyCategories()

    this.originalTiles = _cloneDeep(this.tiles)
    this.originalTilesObject = _keyBy(this.originalTiles, 'i')

    // Clean empty rows
    this.resizeCategoryFromContent()
    this._getEmptyRows('service').forEach((er, i) => {
      this.tiles.forEach(t => {
        if (t.y > er) t.y -= (i + 1)
      })
    })
  }

  /**
   * Compare tiles with originalTiles
   */
  get hasChanges () {
    return !_isEqual(this.originalTiles, this.tiles)
  }

  /**
   * Return the bottom coordinate of the tiles.
   */
  get bottom () {
    const sorted = this._sortLayoutTilesByRowCol()
    let max = 0
    let bottomY = 0
    for (let i = 0; i < sorted.length; i++) {
      bottomY = sorted[i].y + sorted[i].height
      if (bottomY > max) max = bottomY
    }

    return max + 1
  }

  /**
   * Return the height of layout.
   */
  get layoutHeight () {
    return (this.bottom - 1) * this.rowHeight + 2 * this.margin[1]
  }

  /**
   * Return max tiles in row
   */
  get maxTilesInRow () {
    return this.colNum / (this.minWidth || 1)
  }

  /**
   * Get all static tiles.
   */
  get _statics () {
    return this.tiles.filter(t => t.static)
  }

  /**
   * Get all categories.
   */
  get _categories () {
    const categories = []

    this.tiles.forEach(t => {
      if (t.type === 'category' && !categories.includes(t.i)) categories.push(t.i)
    })

    return categories
  }

  /**
   * Get all categories.
   */
  get _tilesByCategories () {
    const tilesServices = this.tiles.filter(t => t.category)
    return _groupBy(tilesServices, 'category')
  }

  /**
   * BackupTiles
   */
  backupTiles () {
    this._backup = _cloneDeep(this.originalTilesObject)
  }

  /**
   * Restore tiles from backup
   */
  restoreTiles () {
    this.resetTilesFromOriginalTiles(null, true)
  }

  /**
   * Get item y from top
   */
  getItemY (top) {
    let sumHeight = 0
    let y = null

    for (let i = 0; i <= this.bottom; i += 1) {
      const heightOfRow = this.tiles.find(t => t.y === i)?.height
      sumHeight += heightOfRow + this.margin[1]

      if (sumHeight > top) {
        y = i
        break
      }
    }

    if (y === null) y = this.bottom

    return y
  }

  /**
   * Update tile
   *
   * @param {String} tileId tile.i
   * @param {String} key key to update
   * @param {Any} value new value
   */
  updateTile (tileId, key, value) {
    const tile = this.tiles.find(it => it.i === tileId)
    if (tile) {
      tile[key] = value
    }
  }

  /**
   * Return a copy of the tiles.
   */
  cloneLayout () {
    return _cloneDeep(this.tiles)
  }

  /**
   * Return array of int number from min to max.
   *
   * @param {Number} min Min.
   * @param {Number} max Max.
   */
  _generateNumbers (min, max) {
    const list = []
    for (let i = min; i < max; i++) {
      list.push(i)
    }

    return list
  }

  /**
   * Return list of all rows without any tiles.
   */
  _getEmptyRows (type = null) {
    let rowsUsed = []
    const tiles = type ? this.tiles.filter(t => t.type === type) : this.tiles

    if (!tiles.length) return []

    tiles.forEach(t => {
      rowsUsed = rowsUsed.concat(this._generateNumbers(t.y, t.y + t.height))
    })
    const allNumber = this._generateNumbers(0, _maxBy(tiles, 'y')?.y + 1)

    return allNumber.filter(x => !_uniq(rowsUsed).includes(x))
  }

  /**
   * Given two layout tiles, check if they collide.
   *
   * @return {Boolean} True if colliding.
   */
  _collides (t1, t2) {
    if (t1 === t2) return false // same element
    if (t1.x + t1.width <= t2.x) return false // t1 is left of t2
    if (t1.x >= t2.x + t2.width) return false // t1 is right of t2

    if (t1.y + t1.height <= t2.y) return false // t1 is above t2
    if (t1.y >= t2.y + t2.height) return false // t1 is below t2

    return true // boxes overlap
  }

  /**
   * Get categories tiles sorted from top left to right and down.
   *
   * @param {Boolean} all If true, return all tiles included edit tiles
   */
  _sortCategoryTilesByRowCol (category = '') {
    const tilesToUse = this.tiles.filter(t => t.category === category)
    return tilesToUse.sort((a, b) => {
      if (a.y === b.y && a.x === b.x) return 0
      if (a.y > b.y || (a.y === b.y && a.x > b.x)) return 1
      return -1
    })
  }

  /**
   * Get layout tiles sorted from top left to right and down.
   *
   * @param {Boolean} all If true, return all tiles included edit tiles
   */
  _sortLayoutTilesByRowCol (type = 'service') {
    // const tilesToUse = type ? this.tiles.filter(t => t.type === type) : this.tiles.filter(t => t.type !== 'category')
    const tilesToUse = this.tiles.filter(t => t.type === type)
    return tilesToUse.sort((a, b) => {
      if (a.y === b.y && a.x === b.x) return 0
      if (a.y > b.y || (a.y === b.y && a.x > b.x)) return 1
      return -1
    })
  }

  /**
   * Add empty spot tiles
   */
  addEmptySpotTiles () {
    this.removeEmptySpotTiles()
    // Add placeholder everywhere + maxY+1
    for (let i = 0; i < this.colNum; i++) {
      for (let j = 0; j < this.bottom + 2; j++) {
        this.tiles.push({
          x: i,
          y: j,
          width: 1,
          height: 1,
          type: 'empty-spot',
          static: true,
          i: `${i}-${j}`
        })
      }
    }
  }

  /**
   * Remove empty spot tiles
   */
  removeEmptySpotTiles () {
    this.tiles = this.tiles.filter(t => t.type !== 'empty-spot')
  }

  /**
   * Return if tile is in category or not
   */
  _tileIsInCategory (tile, category) {
    return tile.category === category
  }

  /**
   * Return if tile is in another category or not
   */
  tileIsInAnotherCategory (tile) {
    let check = false
    this._categories.forEach(category => {
      if (category !== tile.category) {
        const categTile = this.tiles.find(t => t.type === 'category' && t.i === category)
        if (
          tile.x >= categTile.x &&
          tile.x < (categTile.x + categTile.width) &&
          tile.y >= categTile.y &&
          tile.y < (categTile.y + categTile.height)
        ) {
          check = true
        }
      }
    })

    return check
  }

  /**
   * Return if tile is outside of its category or not
   */
  tileIsOutsideOfCategory (tile) {
    let check = false

    const categTile = this.tiles.find(t => t.type === 'category' && t.i === tile.i)
    const tilesFromCategory = this.tiles.filter(t => t.category === tile.i)
    tilesFromCategory.forEach(t => {
      if (
        !(t.x >= categTile.x &&
        t.x < (categTile.x + categTile.width) &&
        t.y >= categTile.y &&
        t.y < (categTile.y + categTile.height))
      ) {
        check = true
      }
    })

    return check
  }

  /**
   * Return empty rows in category
   */
  _emptyRowsInCategory (category) {
    let rowsUsed = []
    const tilesFromCategory = this.tiles.filter(t => t.category === category)

    if (!tilesFromCategory.length) return []

    tilesFromCategory.forEach(t => {
      rowsUsed = rowsUsed.concat(this._generateNumbers(t.y, t.y + 1))
    })

    const categTile = this.tiles.find(t => t.type === 'category' && t.i === category)
    const allNumber = this._generateNumbers(categTile?.y, _maxBy(tilesFromCategory, 'y')?.y + 1)

    return allNumber.filter(x => !_uniq(rowsUsed).includes(x))
  }

  /**
   * Compact the tiles. This involves going down each y coordinate and removing gaps
   * between items.
   *
   * @param {Object} minPositions
   */
  compact (minPositions, types = ['service', 'category'], dndService = null, fromClick = false, noReplace = false) {
    types.forEach(type => {
      this._compactType(minPositions, type, type === 'service' ? null : dndService, fromClick, noReplace)
    })
  }

  /**
   * Compact the tiles. This involves going down each y coordinate and removing gaps
   * between items.
   *
   * @param {Object} minPositions
   */
  _compactType (minPositions, type = 'service', dndService = null, fromClick = false, noReplace = false) {
    // Statics go in the compareWith array right away so items flow around them.
    const compareWith = this._statics
    // We go through the items by row and column.
    const sorted = this._sortLayoutTilesByRowCol(type)

    for (let i = 0, len = sorted.length; i < len; i++) {
      const t = sorted[i]

      // Don't move static elements
      if (!t.static && t.type === type) {
        this._compactTile(compareWith, t, minPositions, type, dndService)

        // Add to comparison array. We only collide with items before this one.
        // Statics are already in this array.
        compareWith.push(t)
      }

      // Clear moved flag, if it exists.
      t.moved = false
      if (type === 'category' && (!dndService || (dndService && (!this._tileIsInCategory(dndService, t.i) || (this._tileIsInCategory(dndService, t.i) && this.tileIsOutsideOfCategory(t))))) && !noReplace) this.replaceServiceInCategory(t)
    }
    if (type === 'service') this.resizeCategoryFromContent(fromClick)
  }

  /**
   * Compact a tile in the layout tiles.
   *
   * @param {Array} compareWith
   * @param {layoutTile} t
   * @param {Object} minPositins
   * @param {Array} emptyRows we pass it through params because this array is updated while compact
   */
  _compactTile (compareWith, t, minPositions, type, dndService) {
    if (type === 'service') {
      // Go up if there is empty rows in category
      let i = 0
      this._emptyRowsInCategory(t.category).forEach(r => {
        if (t.y > r) i += 1
      })
      t.y -= i
    }

    if (minPositions && !t.moving && t.i !== dndService?.category) {
      const minY = minPositions[t.i].y
      while (t.y > minY && !this._getFirstCollision(compareWith, t, type)) {
        t.y--
      }
    }

    if (!t.static && t.type === type) {
      // Move it down, and keep moving it down if it's colliding.
      let collides
      while ((collides = this._getFirstCollision(compareWith, t, type))) {
        // t.y = collides.y + t.height
        t.y = collides.y + collides.height
      }
    }
  }

  /**
   * Returns the first tile this tile collides with.
   * It doesn't appear to matter which order we approach this from, although
   * perhaps that is the wrong thing to do.
   *
   * @param {Array} tiles Tiles.
   * @param {Object} tile Layout tile.
   * @param {Boolean} all If true, return all tiles included edeit tiles
   * @return {Object|undefined} A colliding layout tile, or undefined.
   */
  _getFirstCollision (tiles, tile, type = 'service') {
    const cleanTiles = type === 'all' ? tiles.filter(t => ['service', 'category'].includes(t.type)) : tiles.filter(t => t.type === type)
    for (let i = 0, len = cleanTiles.length; i < len; i++) {
      if (this._collides(cleanTiles[i], tile)) {
        return cleanTiles[i]
      }
    }
  }

  /**
   * Returns all tiles this calliding with tile.
   *
   * @param {Array} tiles Tiles.
   * @param {Object} tile Layout tile.
   * @param {Boolean} all If true, return all tiles included edeit tiles
   * @return {Object|undefined} A colliding layout tile, or undefined.
   */
  getAllCollisions (tiles, tile, type = 'service') {
    return tiles.filter(t => t.type === type && this._collides(t, tile))
  }

  /**
   * Given tiles, make sure all elements fit within its bounds.
   *
   * @param {Object} bounds Number of columns.
   */
  correctBounds (bounds) {
    const collidesWith = this._statics
    for (let i = 0, len = this.tiles.length; i < len; i++) {
      const t = this.tiles[i]
      // Overflows right
      if (t.x + t.width > bounds.cols) t.x = bounds.cols - t.width
      // Overflows left
      if (t.x < 0) {
        t.x = 0
        t.width = bounds.cols
      }
      if (!t.static) collidesWith.push(t)
      else {
        // If this is static and collides with other statics, we must move it down.
        // We have to do something nicer than just letting them overlap.
        while (this._getFirstCollision(this.tiles, collidesWith)) {
          t.y++
        }
      }
    }
  }

  /**
   * Get a layout tile by ID. Used so we can override later on if necessary.
   *
   * @param {String} id ID
   * @return {LayoutTile} Item at ID.
   */
  getLayoutTile (id) {
    return this.tiles.find(t => t.i === id) || {}
  }

  /**
   * Get layout tiles by row.
   *
   * @param  {String} row row number
   * @return {LayoutItems} Items in row.
   */
  getRowTiles (row, type = 'service') {
    return this.tiles.filter(t => t.y === row && t.type === type)
  }

  /**
   * Add tile in layout
   *
   * @param {String} type To know where to add the tile
   */
  addTile (tile, type = null) {
    this.compact()
    this.updateOriginalTiles()

    return tile
  }

  /**
   * Delete tile in layout
   *
   * @param {LayoutTIle} tile tile to delete
   */
  deleteTile (tile) {
    this.tiles = this.tiles.filter(t => t.i !== tile.i)
    this.compact()
    this.updateOriginalTiles()
  }

  /**
   * Hide tile of type
   *
   * @param {String} type type to hide
   */
  hideTileType (type) {
    this.tiles.forEach(t => {
      if (t.type === type) t.hide = true
    })
  }

  /**
   * Display tile of type
   *
   * @param {String} type type to display
   */
  displayTileType (type) {
    this.tiles.forEach(t => {
      if (t.type === type) delete t.hide
    })
  }

  /**
   * Reset tiles from origincalTiles
   *
   * @param {Number} y if set, update only tiles in this row
   */
  resetTilesFromOriginalTiles (y = null, backup = false) {
    const originToUse = backup ? this._backup : this.originalTilesObject

    this.tiles.forEach(t => {
      if ((y === t.y || y === null) && t.type !== 'empty-spot') {
        t.width = originToUse[t.i].width
        t.height = originToUse[t.i].height
        t.x = originToUse[t.i].x
        t.y = originToUse[t.i].y
      }
    })
  }

  /**
   * Move an element. Responsible for doing cascading movements of other elements.
   *
   * @param  {LayoutTile} tile      element to move.
   * @param  {Number} x    X position in grid units.
   * @param  {Number} y    Y position in grid units.
   * @param  {Boolean} isUserAction If true, designates that the item we're moving is
   * being dragged/resized by th euser.
   * @param  {Boolean} preventCollision
   */
  moveElement (tile, x, y, isUserAction, preventCollision = false, type = 'service') {
    if (tile.static || tile.type !== type) return

    const oldX = tile.x
    const oldY = tile.y

    const movingUp = y && tile.y > y
    // This is quite a bit faster than extending the object
    if (typeof x === 'number') tile.x = x
    if (typeof y === 'number') tile.y = y
    tile.moved = true

    // If this collides with anything, move it.
    // When doing this comparison, we have to sort the items we compare with
    // to ensure, in the case of multiple collisions, that we're getting the
    // nearest collision.
    let sorted = this._sortLayoutTilesByRowCol(type)
    if (movingUp) sorted = sorted.reverse()
    const collisions = this.getAllCollisions(sorted, tile, type)

    if (preventCollision && collisions.length) {
      tile.x = oldX
      tile.y = oldY
      tile.moved = false
      return
    }

    // Move each item that collides away from this element.
    for (let i = 0, len = collisions.length; i < len; i++) {
      const collision = collisions[i]

      // Short circuit so we can't infinite loop
      if (collision.moved) continue

      if (collision.static) { // Don't move static items - we have to move *this* element away
        this._moveElementAwayFromCollision(collision, tile, isUserAction, type)
      } else {
        this._moveElementAwayFromCollision(tile, collision, isUserAction, type)
      }
    }

    if (this.tiles.find(t => t.type === 'empty-spot')?.length) this.addEmptySpotTiles()
  }

  /**
   * This is where the magic needs to happen - given a collision, move an element away from the collision.
   * We attempt to move it up if there's room, otherwise it goes below.
   *
   * @param  {LayoutTile} collidesWith Layout tile we're colliding with.
   * @param  {LayoutTile} tileToMove Layout tile we're moving.
   * @param  {Boolean} [isUserAction] If true, designates that the tile we're moving is being dragged/resized
   * by the user.
   */
  _moveElementAwayFromCollision (collidesWith, tileToMove, isUserAction, type = 'service') {
    const preventCollision = false // we're already colliding
    // If there is enough space above the collision to put this element, move it there.
    // We only do this on the main collision as this can get funky in cascades and cause
    // unwanted swapping behavior.
    if (isUserAction) {
      // Make a mock item so we don't modify the item here, only modify in moveElement.
      const fakeItem = {
        x: tileToMove.x,
        y: tileToMove.y,
        width: tileToMove.width,
        height: tileToMove.height,
        i: '-1'
      }
      fakeItem.y = Math.max(collidesWith.y - tileToMove.height, 0)
      if (!this._getFirstCollision(this.tiles, fakeItem, type)) {
        return this.moveElement(tileToMove, undefined, fakeItem.y, isUserAction, preventCollision, type)
      }
    }

    // Previously this was optimized to move below the collision directly, but this can cause problems
    // with cascading moves, as an item may actually leapflog a collision and cause a reversal in order.
    return this.moveElement(tileToMove, undefined, tileToMove.y + 1, isUserAction, preventCollision, type)
  }

  resizeTile (tileId, width, height) {
    const tile = this.tiles.find(t => t.i === tileId)
    tile.width = width
    tile.height = height
    if (tile.x + tile.width > this.colNum) {
      tile.x = tile.x - (tile.x + tile.width - this.colNum)

      // Put every tile in static
      this.tiles.forEach(t => {
        if (t.i !== tile.i && t.category === tile.category) t.static = true
      })
      this.moveElement(tile, tile.x, tile.y, true, false, 'service')

      this.tiles.forEach(t => {
        if (t.i !== tile.i && t.category === tile.category) delete t.static
      })

      let collides
      while ((collides = this._getFirstCollision(this.tiles.filter(t => t.category === tile.category), tile, 'service'))) {
        tile.y = collides.y + collides.height
      }

      // this.resizeCategoryFromContent()
      this.compact(null, ['service', 'category'], null, null, true)
    } else {
      this.moveElement(tile, tile.x, tile.y, true, false, 'service')
      // this.resizeCategoryFromContent()
      this.compact(null, ['service', 'category'], null, null, true)
    }
    this._categories.forEach(category => {
      if (category !== tile.category) {
        const categoryTile = this.tiles.find(t => t.i === category)
        this.replaceServiceInCategory(categoryTile)
      }
    })
    this.resizeCategoryFromContent()
    this.updateOriginalTiles()

    // Put every tile in static
    const categoryTile = this.tiles.find(t => t.i === tile.category)
    this.tiles.forEach(t => {
      if (t.i !== categoryTile.i && t.type === 'category') t.static = true
    })
    this.moveElement(categoryTile, categoryTile.x, categoryTile.y, true, false, 'category')
    this.tiles.forEach(t => {
      if (t.i !== categoryTile.i && t.type === 'category') delete t.static
    })
    this.replaceServiceInCategory(categoryTile)
    // this.resizeCategoryFromContent(tile)

    if (this.tiles.find(t => t.type === 'empty-spot')?.length) this.addEmptySpotTiles()
  }

  /**
   * Resize the category from its content
   *
   */
  resizeCategoryFromContent (fromClick) {
    this._categories.forEach(category => {
      const tiles = this._tilesByCategories[category]
      if (tiles && (!fromClick || (fromClick && category === fromClick.category))) {
        const minX = _minBy(tiles, 'x').x
        const minY = _minBy(tiles, 'y').y
        const maxWidth = _maxBy(tiles, t => t.x + t.width)
        const maxHeight = _maxBy(tiles, t => t.y + t.height)

        const categTile = this.tiles.find(t => t.type === 'category' && t.i === category)
        categTile.x = minX
        categTile.y = minY
        categTile.width = maxWidth.x + maxWidth.width - minX
        categTile.height = maxHeight.y + maxHeight.height - minY
      }
    })
  }

  /**
   * Replace services in their category
   *
   */
  replaceServiceInCategory (categoryTile) {
    const originalTile = this.originalTilesObject[categoryTile.i]

    // Get x and y difference from original and apply it to services in category
    const xDiff = categoryTile.x - originalTile.x
    const yDiff = categoryTile.y - originalTile.y

    this.tiles.forEach(t => {
      if (t.type === 'service' && t.category === categoryTile.i) {
        const originalServiceTile = this.originalTilesObject[t.i]
        t.x = originalServiceTile.x + xDiff
        t.y = originalServiceTile.y + yDiff
      }
    })
  }

  /**
   * Validate tiles format. Throws errors.
   *
   * @throw {Error} Validation error.
   */
  validateTiles () {
    const contextName = 'Layout'
    const subProps = ['x', 'y', 'width', 'height']
    const keyArr = []
    if (!Array.isArray(this.tiles)) throw new Error(contextName + ' must be an array!')
    for (let i = 0, len = this.tiles.length; i < len; i++) {
      const item = this.tiles[i]
      for (let j = 0; j < subProps.length; j++) {
        if (typeof item[subProps[j]] !== 'number') {
          throw new Error('VueGridLayout: ' + contextName + '[' + i + '].' + subProps[j] + ' must be a number!')
        }
      }

      if (item.i === undefined || item.i === null) {
        throw new Error('VueGridLayout: ' + contextName + '[' + i + '].i cannot be null!')
      }

      if (typeof item.i !== 'number' && typeof item.i !== 'string') {
        throw new Error('VueGridLayout: ' + contextName + '[' + i + '].i must be a string or number!')
      }

      if (keyArr.indexOf(item.i) >= 0) {
        throw new Error('VueGridLayout: ' + contextName + '[' + i + '].i must be unique!')
      }
      keyArr.push(item.i)

      if (item.static !== undefined && typeof item.static !== 'boolean') {
        throw new Error('VueGridLayout: ' + contextName + '[' + i + '].static must be a boolean!')
      }
    }
  }
}

export default Layout
