
import Vue from 'vue'
import _get from 'lodash/get'

export default {
  state: {
    logicalObjects: null,
    logicalObjectsLoading: false,
    databases: null,
    databasesLoading: false,
    rules: null,
    rulesLoading: false,
    notebooks: null,
    notebooksLoading: false,
    dashboards: null,
    dashboardsLoading: false,
    queryHistory: null,
    queryHistoryLoading: false,
    activeAlerts: null,
    activeAlertsLoading: false,
    applications: null,
    applicationsLoading: false,
    usersSessions: null,
    usersSessionsLoading: false,
    assistants: null,
    assistantsLoading: false,
    knowledgeBases: null,
    knowledgeBasesLoading: false,
    CardInfoLoading: false,
    configurationAM: {},
    users: []
  },
  mutations: {
    SET_LOGICAL_OBJECTS (state, logicalObjects) { // Not WS
      state.logicalObjects = logicalObjects
    },
    SET_LOGICAL_OBJECTS_LOADING (state, logicalObjectsLoading) { // Not WS
      state.logicalObjectsLoading = logicalObjectsLoading
    },
    SET_DATABASES (state, databases) { // Not WS
      state.databases = databases
    },
    SET_DATABASES_LOADING (state, databasesLoading) { // Not WS
      state.databasesLoading = databasesLoading
    },
    SET_RULES (state, rules) { // Not WS
      state.rules = rules
    },
    SET_RULES_LOADING (state, rulesLoading) { // Not WS
      state.rulesLoading = rulesLoading
    },
    SET_NOTEBOOKS (state, notebooks) { // Not WS
      state.notebooks = notebooks
    },
    SET_NOTEBOOKS_LOADING (state, notebooksLoading) { // Not WS
      state.notebooksLoading = notebooksLoading
    },
    SET_DASHBOARDS (state, dashboards) { // Not WS
      state.dashboards = dashboards
    },
    SET_DASHBOARDS_LOADING (state, dashboardsLoading) { // Not WS
      state.dashboardsLoading = dashboardsLoading
    },
    SET_QUERY_HISTORY (state, queryHistory) { // Not WS
      state.queryHistory = queryHistory
    },
    SET_QUERY_HISTORY_LOADING (state, queryHistoryLoading) { // Not WS
      state.queryHistoryLoading = queryHistoryLoading
    },
    SET_ACTIVE_ALERTS (state, activeAlerts) { // Not WS
      state.activeAlerts = activeAlerts
    },
    SET_ACTIVE_ALERTS_LOADING (state, activeAlertsLoading) { // Not WS
      state.activeAlertsLoading = activeAlertsLoading
    },
    SET_APPLICATIONS (state, applications) { // Not WS
      state.applications = applications
    },
    SET_APPLICATIONS_LOADING (state, applicationsLoading) { // Not WS
      state.applicationsLoading = applicationsLoading
    },
    SET_USERS_SESSIONS (state, usersSessions) { // Not WS
      state.usersSessions = usersSessions
    },
    SET_USERS_SESSIONS_LOADING (state, usersSessionsLoading) { // Not WS
      state.usersSessionsLoading = usersSessionsLoading
    },
    SET_CARD_INFO_LOADING (state, CardInfoLoading) { // Not WS
      state.CardInfoLoading = CardInfoLoading
    },
    SET_AM_RESOURCES_CONFIGURATION (state, configurationAM) { // Not WS
      state.configurationAM = configurationAM
    },
    SET_ASSISTANTS (state, assistants) {
      state.assistants = assistants
    },
    SET_ASSISTANTS_LOADING (state, assistantsLoading) {
      state.assistantsLoading = assistantsLoading
    },
    SET_KNOWLEDGE_BASES (state, knowledgeBases) {
      state.knowledgeBases = knowledgeBases
    },
    SET_KNOWLEDGE_BASES_LOADING (state, knowledgeBasesLoading) {
      state.knowledgeBasesLoading = knowledgeBasesLoading
    },
    SET_USERS (state, users) {
      state.users = users
    }
  },
  actions: {
    async LOAD_LOGICAL_OBJECTS ({ commit, state }) {
      commit('SET_LOGICAL_OBJECTS_LOADING', true)
      try {
        const items = await Vue.api.DM.logicalObjects.list()
        commit('SET_LOGICAL_OBJECTS', items)
      } catch (err) {
        commit('SET_LOGICAL_OBJECTS', null)
      }
      commit('SET_LOGICAL_OBJECTS_LOADING', false)
    },
    async LOAD_DATABASES ({ commit, state }) {
      commit('SET_DATABASES_LOADING', true)
      try {
        const items = await Vue.api.DM.databases.list()
        commit('SET_DATABASES', items.filter(object => object.level === 'source'))
      } catch (err) {
        commit('SET_DATABASES', null)
      }
      commit('SET_DATABASES_LOADING', false)
    },
    async LOAD_RULES ({ commit, state }) {
      commit('SET_RULES_LOADING', true)
      try {
        const items = await Vue.api.DM.rules.list()
        commit('SET_RULES', items)
      } catch (err) {
        commit('SET_RULES', null)
      }
      commit('SET_RULES_LOADING', false)
    },
    async LOAD_NOTEBOOKS ({ commit, state }) {
      commit('SET_NOTEBOOKS_LOADING', true)
      try {
        const items = await Vue.api.ML.notebooks.list()
        commit('SET_NOTEBOOKS', items)
      } catch (err) {
        commit('SET_NOTEBOOKS', null)
      }
      commit('SET_NOTEBOOKS_LOADING', false)
    },
    async LOAD_DASHBOARDS ({ commit, state }) {
      commit('SET_DASHBOARDS_LOADING', true)
      try {
        const items = await Vue.api.QUERY_ADMIN.dashboards.list()
        commit('SET_DASHBOARDS', items)
      } catch (err) {
        commit('SET_DASHBOARDS', null)
      }
      commit('SET_DASHBOARDS_LOADING', false)
    },
    async LOAD_QUERY_HISTORY ({ commit, state }) {
      commit('SET_QUERY_HISTORY_LOADING', true)
      try {
        const items = await Vue.api.QUERY_ADMIN.queriesHistory.list()
        commit('SET_QUERY_HISTORY', items)
      } catch (err) {
        commit('SET_QUERY_HISTORY', []) // We do not need it to be null
      }
      commit('SET_QUERY_HISTORY_LOADING', false)
    },
    async LOAD_ACTIVE_ALERTS ({ commit, state }) {
      commit('SET_ACTIVE_ALERTS_LOADING', true)
      try {
        const items = await Vue.api.CC.alerts.list()
        commit('SET_ACTIVE_ALERTS', items)
      } catch (err) {
        commit('SET_ACTIVE_ALERTS', null)
      }
      commit('SET_ACTIVE_ALERTS_LOADING', false)
    },
    // If you want to use only the login session, use 'last_connected_at' under users api call
    async LOAD_USERS_SESSIONS ({ commit, state }) {
      commit('SET_USERS_SESSIONS_LOADING', true)
      try {
        const names = await Vue.api.IAM.users.names()
        const sessionsList = await Vue.api.IAM.metrics.list()

        const usersSessions = {}
        const sessions = sessionsList?.sessions || []
        sessions
          .filter(session => session.user)
          .forEach(session => {
            const uniqkey = session.authentication_provider + session.user
            const lastConnected = session.end > _get(usersSessions[uniqkey], 'end', 0) ? session.end : _get(usersSessions[uniqkey], 'end', 0)
            usersSessions[uniqkey] = {
              _id: session.user,
              lastConnected,
              end: session.end,
              user: names.find(n => n._id === session.user)?.displayname || session.user
            }
          })
        commit('SET_USERS_SESSIONS', usersSessions)
      } catch (err) {
        commit('SET_USERS_SESSIONS', null)
      }
      commit('SET_USERS_SESSIONS_LOADING', false)
    },
    async LOAD_AM_RESOURCES ({ commit }) {
      const configuration = await Vue.api.QUERY_ADMIN.resources.list()
      commit('SET_AM_RESOURCES_CONFIGURATION', configuration)
    },
    async LOAD_USERS ({ commit }) {
      try {
        await Vue.api.IAM.users.list({
          onProgress: (users) => {
            commit('SET_USERS', users)
          }
        })
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
        console.error(err)
      }
    }
  },
  getters: {
    PROJECT_HOME_LOGICAL_OBJECTS_LOADING (state) {
      return state.logicalObjectsLoading
    },
    PROJECT_HOME_LOGICAL_OBJECTS (state) {
      return state.logicalObjects
    },
    PROJECT_HOME_DATABASES_LOADING (state) {
      return state.databasesLoading
    },
    PROJECT_HOME_DATABASES (state) {
      return state.databases
    },
    PROJECT_HOME_RULES_LOADING (state) {
      return state.rulesLoading
    },
    PROJECT_HOME_RULES (state) {
      return state.rules
    },
    PROJECT_HOME_NOTEBOOKS_LOADING (state) {
      return state.notebooksLoading
    },
    PROJECT_HOME_NOTEBOOKS (state) {
      return state.notebooks
    },
    PROJECT_HOME_DASHBOARDS_LOADING (state) {
      return state.dashboardsLoading
    },
    PROJECT_HOME_DASHBOARDS (state) {
      return state.dashboards
    },
    PROJECT_HOME_QUERY_HISTORY_LOADING (state) {
      return state.queryHistoryLoading
    },
    PROJECT_HOME_QUERY_HISTORY (state) {
      return state.queryHistory
    },
    PROJECT_HOME_ACTIVE_ALERTS_LOADING (state) {
      return state.activeAlertsLoading
    },
    PROJECT_HOME_ACTIVE_ALERTS (state) {
      return state.activeAlerts
    },
    PROJECT_HOME_APPLICATIONS_LOADING (state) {
      return state.applicationsLoading
    },
    PROJECT_HOME_APPLICATIONS (state) {
      return state.applications
    },
    PROJECT_HOME_USERS_SESSIONS_LOADING (state) {
      return state.usersSessionsLoading
    },
    PROJECT_HOME_USERS_SESSIONS (state) {
      return state.usersSessions
    },
    PROJECT_CARD_INFO_LOADING (state) {
      return state.CardInfoLoading
    },
    PROJECT_TRINO_ACTIVE (state) {
      return state.configurationAM.active
    },
    PROJECT_HOME_ASSISTANTS_LOADING (state) {
      return state.assistantsLoading
    },
    PROJECT_HOME_ASSISTANTS (state) {
      return state.assistants
    },
    PROJECT_HOME_KNOWLEDGE_BASES_LOADING (state) {
      return state.knowledgeBasesLoading
    },
    PROJECT_HOME_KNOWLEDGE_BASES (state) {
      return state.knowledgeBases
    },
    PROJECT_HOME_USERS (state) {
      return state.users
    }
  }
}
