import '@/shared'
import Chargebee from '@/core/Chargebee'
import Analytics from '@/shared/Analytics'
import { isDataplatform, setConfig } from '@/shared/Config'
import I18n from '@/shared/I18n'
import '@/shared/Sentry'
import FpStore from '@/shared/store'
import 'font-awesome/css/font-awesome.min.css'
import App from './App'
import appConfig from './config/global'
import FpRouter from './router'
import StatusPage from '@/core/StatusPageWidget'
import coreStore from './store'

import createFpuiApp from '../shared/sharedConfig'

setConfig(appConfig)

const app = createFpuiApp(
  {
    components: { App },
    template: '<App/>',
    beforeCreate () {
      if (!isDataplatform()) Chargebee.load()
    },
    mounted () {
      if (!isDataplatform()) StatusPage.load()
    }
  }
)

const originalPush = FpRouter.push
FpRouter.push = function push (location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  })
}

app.config.globalProperties.$router = FpRouter
app.use(FpRouter)
Analytics.init(FpRouter, 'FPUI', app)
FpStore.registerModule('core', coreStore)
app.use(FpStore)
I18n.load('core')

// Vue config
app.config.__VUE_PROD_DEVTOOLS__ = process.env.NODE_ENV === 'development'

app.mount('body')
