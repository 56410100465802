export default {
  async load (url = 'https://js.chargebee.com/v2/chargebee.js') {
    return new Promise((resolve, reject) => {
      // Check if the script is already loaded
      if (document.querySelector(`script[src="${url}"]`)) {
        // For debugging purposes
        // console.info(`Script already loaded: ${url}`)
        resolve()
        return
      }

      // Dynamically create the script tag
      const script = document.createElement('script')
      script.src = url

      // Resolve the promise when the script is loaded
      script.onload = () => {
        // For debugging purposes
        // console.info(`Script loaded successfully: ${url}`)
        resolve()
      }

      // Reject the promise if there's an error loading the script
      script.onerror = () => {
        // For debugging purposes
        // console.error(`Failed to load script: ${url}`)
        reject(new Error(`Failed to load script: ${url}`))
      }

      // Append the script to the document head
      document.head.appendChild(script)
    })
  }
}
