import Dpe from './DPE'
import Dm from './DM'
import DataCatalog from './DataCatalog'
import Lakehouse from './Lakehouse'
import Am from './AM'
import Mlm from './MLM'
import Cc from './CC'
import Iam from './IAM'
import Api from './API'
import App from './APP'

export default {
  ...Dpe,
  ...Dm,
  ...DataCatalog,
  ...Lakehouse,
  ...Am,
  ...Mlm,
  ...Cc,
  ...Iam,
  ...Api,
  ...App
}
