import Config from '@/shared/Config'
import { User } from '@/shared/plugins/Api/KING/Users'
import TokenInjector from '@/shared/plugins/Api/TokenInjector'
import FpStore from '@/shared/store'
import moment from 'moment'
import Vue from 'vue'
import support from './support'
import projectHome from './projectHome'
import acl from './acl'
import _get from 'lodash/get'

const TIME_BETWEEN_CHECK_SESSION = (900 + Math.random() * 900) * 1000 // Every 15min-30-min

const TIME_BEFORE_REFRESH_SESSION = 3600 * 1000 // ! hour

const Store = {
  modules: { support, projectHome, acl },
  state: {
    cmpSession: null,
    ready: {
      core_organizations: false,
      core_accounts: false,
      core_prices: false,
      users_subscriptions: false,
      users_orga_subscriptions: false,
      acl: false
    },
    organization_preferences: null,
    organization_logged: false,
    organization_loading: false,
    need_organization_mfa: false,
    // This is used where we want to use the organization session in the Core
    // i.e. in the Home of the Organization
    organization_session: null
  },
  mutations: {
    SET_READY (state, { key, value }) { state.ready[key] = value }, // Not WS
    SET_ORGANIZATION_PREFERENCES (state, preferences) { state.organization_preferences = preferences },
    SET_NEED_ORGANIZATION_LOGIN (state, value) { state.organization_logged = !value },
    SET_NEED_ORGANIZATION_MFA (state, value) { state.need_organization_mfa = value },
    SET_ORGANIZATION_LOADING (state, value) { state.organization_loading = value },
    SET_ORGANIZATION_SESSION (state, value) {
      state.organization_session = new User(value)
      // Assigning the session to the TokenInjector cache so we won't make the checksession request twice
      TokenInjector.assign(value)
    }
  },
  actions: {
    async LOGIN_DONE ({ commit, dispatch }) {
      Promise.all([
        {
          key: 'core_organizations',
          name: 'LOAD_ORGANIZATIONS'
        }, {
          key: 'core_accounts',
          name: 'LOAD_ACCOUNTS'
        }, {
          key: 'core_prices',
          name: 'LOAD_PRICES'
        }
      ].map(async action => {
        try {
          if (
            typeof (action.access) === 'undefined' ||
            (typeof (action.access) === 'function' && await action.access()) ||
            await action.access
          ) await dispatch(action.name)
        } catch (err) {
          if (err.message.includes('Error: timeout of')) {
            err.message = `ServerError:Timeout:${action.name}`
          }
          Vue.fpuiMessageBlock.error(err)
          console.error(err)
        } finally {
          commit('SET_READY', { key: action.key, value: true })
        }
      }))
    },

    async CHECK_CMP_SESSION ({ commit, dispatch }, options = {}) {
      let user = null
      try {
        user = await Vue.api.KING.users.me({ noOrganizationId: true })
      } catch (_err) {
        // Not logged, redirect to king login page
        const config = await Config()
        document.location.href = `${config.KING}/oauth/authorize?client_id=hq&response_type=token&redirect_uri=${window.location.origin}`
        return
      }
      user = new User(user)

      commit('SET_SESSION', user)
      if (Vue.$analytics && user.email) {
        Vue.$analytics.identify(user.email, {}, FpStore.getters.ANALYTICS_CONFIGURATION)
      }
      // if session too old
      if (!options.refresh && user.token && moment() - moment(user.token.updated_at) > TIME_BEFORE_REFRESH_SESSION) {
        return dispatch('REFRESH')
      }

      setTimeout(_ => {
        dispatch('CHECK_CMP_SESSION')
      }, TIME_BETWEEN_CHECK_SESSION)

      dispatch('LOGIN_DONE')
    },

    async REFRESH_CURRENT_USER ({ commit }) {
      // Use to force refresh user informations
      const user = new User(await Vue.api.KING.users.me())
      commit('SET_SESSION', user)
    },

    async INIT_CHARGEBEE (_, currencyCode = 'EUR') {
      const config = await Config()
      if (!this.config?.DATA_PLATFORM_ACCESS) {
        // After include, Init Chargebee
        window.Chargebee.init({
          site: config.CHARGEBEE[currencyCode.toUpperCase()].SITE || config.CHARGEBEE.EUR.SITE,
          publishableKey: config.CHARGEBEE[currencyCode.toUpperCase()].APIKEY || config.CHARGEBEE.EUR.APIKEY
        })
      }
    },

    async REFRESH ({ dispatch }) {
      try {
        await Vue.api.KING.users.me()
        await TokenInjector.delete()
      } catch (_err) {
        await Vue.api.KING.logout()
        document.location.href = window.location.origin
      }
      dispatch('CHECK_CMP_SESSION', { refresh: true })
    },
    async LOGOUT_CMP ({ dispatch }, redirect = true) {
      // noOrganizationId arg removes the `Organization-Id` header in the /logout endpoint
      // so the King cookies will be always set to empty. Else, when clicking logout inside an Organization
      // the `Organization-Id` header would be sent and the user would only be logged out from the Organization
      await Vue.api.KING.logout({ noOrganizationId: true })
      FpStore.commit('REMOVE_ACTIVE_ORGANIZATION')
      if (redirect) document.location.href = window.location.origin
    },
    async ORGANIZATION_INIT ({ commit, dispatch, getters }) {
      commit('SET_ORGANIZATION_LOADING', true)
      const config = await Config()
      try {
        // Check if the user needs to be logged in into the organization if has organizationId set.
        // As the ORGANIZATION_INIT method is triggered in the created() of App.vue,
        // when the user is in the organization selection (aka he doesn't have an active organization),
        // we need to make sure the checksession (KING.users.me()) is not triggered twice, since mounted()
        // in App.vue will dispatch CHECK_CMP_SESSION, that is also calling KING.users.me(). So the priority
        // in that case is to trigger the checksession from the mounted only.
        if (config.ORGANIZATION_ID) {
          const organizationSession = await Vue.api.KING.users.me({ noReconnect: true }, false)
          commit('SET_ORGANIZATION_SESSION', organizationSession)
        }
        commit('SET_NEED_ORGANIZATION_LOGIN', false)
      } catch (err) {
        // Login into the Organization IAM
        // Get Organization preferences to get the auth modes
        const organizationPreferences = await Vue.api.FPAPI.preferences('forepaas', config.ORGANIZATION_ID)
        commit('SET_ORGANIZATION_PREFERENCES', organizationPreferences)

        // Check if organization has MFA enforced
        // If the user doesn't have MFA, display the MFA page to activate it
        const currentUserPreferences = await Vue.api.KING.users.me({ noOrganizationId: true })
        const targetOrganization = getters.ORGANIZATION_BY_ID(config.ORGANIZATION_ID)

        const authModes = getters.ORGANIZATION_AUTH_MODES(true)
        if (!currentUserPreferences?.mfa?.active && _get(targetOrganization, 'force_mfa.0.active')) {
          commit('SET_NEED_ORGANIZATION_MFA', true)
          commit('SET_NEED_ORGANIZATION_LOGIN', true)
        } else if (authModes.length) {
          // If multiple auth modes, commit to show the authentication auth mode page
          // else if only one, use forepaas (King reply) or login redirect (sso enforce)

          if (authModes.length > 1) commit('SET_NEED_ORGANIZATION_LOGIN', true)
          else {
            const forepaasAuthModeIndex = authModes.findIndex(m => m.type === 'forepaas')
            if (forepaasAuthModeIndex !== -1 && authModes[forepaasAuthModeIndex]) {
              try {
                const { token } = await Vue.api.KING.users.me({ noOrganizationId: true })
                const organizationSession = await Vue.api.KING.reply(token)
                commit('SET_ORGANIZATION_SESSION', organizationSession)
              } catch (errorReply) {
                FpStore.commit('REMOVE_ACTIVE_ORGANIZATION')
                console.error(errorReply)
                window.open('/', '_self')
              }
              commit('SET_NEED_ORGANIZATION_LOGIN', false)
            } else {
              // If SSO enforce
              commit('SET_NEED_ORGANIZATION_LOGIN', true)
            }
          }
        } else throw Error('NO_AUTH_MODE')
      } finally {
        commit('SET_ORGANIZATION_LOADING', false)
      }
    }
  },

  getters: {
    READY (state) { return (key = 'root') => state.ready[key] },
    READY_ORGANIZATION (state) { return state.ready.core_organizations },
    ORGANIZATION_LOADING (state) { return state.organization_loading },
    ORGANIZATION_PREFERENCES (state) { return state.organization_preferences },
    ORGANIZATION_AUTH_MODES (state) {
      return (f = false) => {
        if (!state.organization_preferences) return []
        if (f) return state.organization_preferences?.auth_mode?.filter(a => a.visible && !a.hidden && a.type !== 'apikey')
        return state.organization_preferences?.auth_mode
      }
    },
    NEED_ORGANIZATION_LOGIN (state) { return !state.organization_logged },
    NEED_ORGANIZATION_MFA (state) { return state.need_organization_mfa },
    ORGANIZATION_SESSION (state) { return state.organization_session }
  }
}
export default Store
