import Vue from 'vue'

export default {
  state: {},
  mutations: {
    SET_ACL (state, { key, value }) { // Not WS
      state[key] = value
    }
  },
  actions: {
    async LOAD_ALL_ACLS ({ commit }, services = []) {
      let aclList = []

      if (services.includes('dpe')) {
        aclList = aclList.concat([
          { key: 'canDpeActionRead', service: 'dpe', resource: 'action', action: 'read' },
          { key: 'canDpeWorkflowRead', service: 'dpe', resource: 'workflow', action: 'read' },
          { key: 'canDpeJobRead', service: 'dpe', resource: 'job', action: 'read' },
          { key: 'canDpeNotebookRead', service: 'dpe', resource: 'notebook', action: 'read' },
          { key: 'dpeExportService', service: 'dpe', resource: 'backup', action: 'read' }
        ])
      }

      if (services.includes('am')) {
        aclList = aclList.concat([
          { key: 'canAmQueryRead', service: 'am', resource: 'query', action: 'read' },
          { key: 'canAmQueryHistory', service: 'am', resource: 'query', action: 'history' },
          { key: 'canAmQueryWrite', service: 'am', resource: 'query', action: 'write' },
          { key: 'canAmDashboardRead', service: 'am', resource: 'dashboard', action: 'read' }
        ])
      }

      if (services.includes('dm')) {
        aclList = aclList.concat([
          { key: 'canDmLogicalRead', service: 'dm', resource: 'logical', action: 'read' },
          { key: 'canDmDatabaseRead', service: 'dm', resource: 'database', action: 'read' },
          { key: 'canDmDatabaseWrite', service: 'dm', resource: 'database', action: 'write' },
          { key: 'canDmExplorerRead', service: 'dm', resource: 'explorer', action: 'read' },
          { key: 'dmExportService', service: 'dm', resource: 'backup', action: 'read' },
          { key: 'canDatastoreBucketRead', service: 'datastore', resource: 'bucket', action: 'read' }
        ])
      }

      if (services.includes('mlm')) {
        aclList = aclList.concat([
          { key: 'canMlNotebookRead', service: 'ml', resource: 'notebook', action: 'read' },
          { key: 'canMlModelRead', service: 'ml', resource: 'model', action: 'read' },
          { key: 'canMlJobRead', service: 'ml', resource: 'job', action: 'read' },
          { key: 'canMlNotebookWrite', service: 'ml', resource: 'notebook', action: 'write' },
          { key: 'mlmExportService', service: 'ml', resource: 'backup', action: 'read' }
        ])
      }

      if (services.includes('control-center')) {
        aclList = aclList.concat([
          { key: 'canCcMonitoringRead', service: 'cc', resource: 'monitoring', action: 'read' },
          { key: 'canCcAlertRead', service: 'cc', resource: 'alert', action: 'read' },
          { key: 'canCcLogRead', service: 'cc', resource: 'log', action: 'read' }
        ])
      }

      if (services.includes('iam')) {
        aclList = aclList.concat([
          { key: 'canIamMetricRead', service: 'iam', resource: 'metric', action: 'read' },
          { key: 'canIamUserRead', service: 'iam', resource: 'user', action: 'read' },
          { key: 'canIamUserWrite', service: 'iam', resource: 'user', action: 'write' },
          { key: 'canIamRoleWrite', service: 'iam', resource: 'role', action: 'write' },
          { key: 'iamExportService', service: 'iam', resource: 'configuration', action: 'read' }
        ])
      }

      if (services.includes('appservice')) {
        aclList = aclList.concat([
          { key: 'canAppserviceApplicationsRead', service: 'appservice', resource: 'applications', action: 'read' },
          { key: 'canAppserviceApplicationsCreate', service: 'appservice', resource: 'applications', action: 'create' }
        ])
      }


      Promise.all(aclList.map(async acl => {
        try {
          const data = await Vue.api.IAM.can(acl.service, acl.resource, acl.action, acl.object)
          commit('SET_ACL', { key: acl.key, value: data })
        } catch (err) {}
      }))
    }
  },
  getters: {
    ACL (state) {
      return key => key in state ? state[key] : true
    }
  }
}

